// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__Y\\+F0s{cursor:pointer;padding:4px 8px;color:var(--primary-color);border:var(--primary-border);border-radius:6px;background:rgba(var(--primary-color-rgb), 0.05)}.Button_button__Y\\+F0s:hover{background:rgba(var(--primary-color-rgb), var(--hover-opacity))}.Button_button-seconday__tAahY{color:var(--secondary-color)}.Button_button-tertiary__vBY9Q{color:var(--tertiary-color)}.Button_button-light__PKcfj{background:none}.Button_button-underline__Djsjy{border:rgba(0,0,0,0);border-bottom:var(--primary-border);border-radius:0}.Button_button-center__qfANd{display:flex;align-items:center;gap:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__Y+F0s`,
	"button-seconday": `Button_button-seconday__tAahY`,
	"buttonSeconday": `Button_button-seconday__tAahY`,
	"button-tertiary": `Button_button-tertiary__vBY9Q`,
	"buttonTertiary": `Button_button-tertiary__vBY9Q`,
	"button-light": `Button_button-light__PKcfj`,
	"buttonLight": `Button_button-light__PKcfj`,
	"button-underline": `Button_button-underline__Djsjy`,
	"buttonUnderline": `Button_button-underline__Djsjy`,
	"button-center": `Button_button-center__qfANd`,
	"buttonCenter": `Button_button-center__qfANd`
};
export default ___CSS_LOADER_EXPORT___;
